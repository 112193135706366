
























































































import { Component, Vue } from 'vue-property-decorator';
import { dispatchCreateYtTask, dispatchGetYtTasks } from '@/store/yt-tasks/actions';
import { IYtTaskCreate } from '@/interfaces/yt-tasks';

@Component
export default class YtCreateTask extends Vue {
  public valid = false;
  public name = '';
  public description = '';
  public deep = 30;
  public file: File | null = null;
  public nameRules = [
    (v: string) => !!v || 'Поле обязательно',
    (v: string) => (v && v.length >= 12) || 'Название должно быть больше 12 символов',
  ];

  public async mounted() {
    await dispatchGetYtTasks(this.$store);
    this.reset();
  }

  public reset() {
    this.name = this.description = '';
    this.deep = 30;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const createdTask: IYtTaskCreate = {
        name: this.name,
        deep: this.deep,
        description: this.description || undefined,
        file: this.file,
      };
      await dispatchCreateYtTask(this.$store, createdTask);
      this.$router.push('/main/youtube-tasks');
    }
  }

}
